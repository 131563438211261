/* Flex animations */
.animate-col {
  transition: width 0.5s ease-in-out;
}

/* Global fade page transitions */
@keyframes opacity-in {
  0% { opacity: 0; }
  100% { opacity: 1 }
}

.block-fade-in {
  animation: opacity-in 0.1s ease-in;
}

.block-fade-in-long {
  animation: opacity-in 0.25s ease-in;
}

.block-fade-out {
  opacity: 0;
  transition: opacity 0.1s ease-out
}

.block-fade-out-long {
  opacity: 0;
  transition: opacity 0.25s ease-out
}

/* General animations */

.infinite-fade-in-out {
  animation: infinite-fade-in-out 2.5s ease-in-out infinite;
}

.hover-underline::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #001DD2;
  transition: width .3s;
}

.hover-underline-success::after {
  background: #198754;
}

.hover-underline:hover::after {
  width: 100%;
}

@keyframes infinite-fade-in-out {
  0% {
    opacity: 0.5;
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 0.5;
  }
}