/* Status Badge Classes */
span[class^="status-badge"] {
  border-radius: 4px;
  white-space: nowrap;
}
.status-badge-sm {
  font-size: 13px;
  padding: 3px 6px;
}
.status-badge-lg {
  font-size: 15px;
  padding: 4px 8px;
}
.status-badge-available {
  color: #545454;
  background-color: #ffffff;
  border: 1px solid #545454;
}
.status-badge-preparing {
  color: #3e66fb;
  background-color: #bdcbfe;
  border: 1px solid #9eb2fd
}
.status-badge-charging {
  color: #ffffff;
  background-color: #001dd2;
  border: 1px solid #808ee9
}
.status-badge-suspendedEVSE {
  color: #3e66fb;
  background-color: #bdcbfe;
  border: 1px solid #9eb2fd
}
.status-badge-suspendedEV {
  color: #008001;
  background-color: #ffffff;
  border: 1px solid #008001;
}
.status-badge-finishing {
  color: #ffffff;
  background-color: #3cc13b;
  border: 1px solid #9de09d
}
.status-badge-faulted {
  color: #ffffff;
  background-color: #f03738;
  border: 1px solid #f03738
}
.status-badge-unavailable {
  color: #f03738;
  background-color: #fac3c3;
  border: 1px solid #f35f60
}
.status-badge-offline {
  color: #00aeef;
  background-color: #e5f7fd;
  border: 1px solid #80dbff
}
.status-badge-downloading {
  color: #853de1;
  background-color: #f8f2ff;
  border: 1px solid #dabdff
}
.status-badge-rebootAfterReset {
  color: #853de1;
  background-color: #fdebeb;
  border: 1px solid #f79b9b
}
.status-badge-unknown {
  color: #545454;
  background-color: #dddddd;
  border: 1px solid #a9a9a9
}